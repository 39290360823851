import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import theme from "../../App/theme";

const AvisoPrivacidad = () => {
  return (
    <Box>
      <Helmet>
        <title>Aviso de Privacidad | Casas Pecsa App</title>
        <meta
          name="description"
          content="Conozca cómo recopilamos, utilizamos y protegemos su información personal en nuestra aplicación. Nuestro aviso de privacidad detalla el uso de datos, sus derechos como usuario y nuestras medidas de seguridad para garantizar la protección de su información."
        />
      </Helmet>
      <Container
        component={"section"}
        sx={{
          marginY: 16,
          paddingY: 3,
          borderRadius: 1,
          display: "grid",
          gap: 3,
          boxShadow: "3px 3px 10px 1px rgba(0,0,0,0.2)",
        }}
      >
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h2"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading2"
          >
            AVISO DE PRIVACIDAD{" "}
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Nosotros en{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>,
            respetamos su preocupación con respecto a la privacidad, por lo que,
            en el marco de la Ley Federal de Protección de Datos personales en
            Posesión de los Particulares, es importante que lea cuidadosamente
            el presente Aviso de Privacidad.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Para efectos del presente Aviso de Privacidad, se entenderá que, al
            referirse a{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, se
            incluye en dicha definición a sus respectivas Afiliadas. “Afiliadas”
            significa, cualquier persona física o moral, incluyendo cualquier
            sociedad mercantil, sociedad civil, asociación, entidad,
            fideicomiso, y/u organización de cualquier tipo (en lo sucesivo
            “Persona”), que directa o indirectamente a través de uno o más
            intermediarios, controle, sea controlada por, o se encuentre bajo
            control común con cualquier otra persona.
          </Typography>
        </Box>
        <Box>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
          >
            TABLA DE CONTENIDOS
          </Typography>
          <ol>
            <li>
              <a href="#collection-of-information">IDENTIDAD Y ALCANCE</a>
            </li>
            <li>
              <a href="#use-and-processing-of-collected-information">
                FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES
              </a>
            </li>
            <li>
              <a href="#disclosure-of-information">TRANSFERENCIAS</a>
            </li>
            <li>
              <a href="#retention-of-information">CONTACTO</a>
            </li>
            <li>
              <a href="#privacy-of-children">LIMITE DE RESPONSABILIDAD</a>
            </li>
            <li>
              <a href="#links-to-other-resources">MODIFICACIONES</a>
            </li>
            <li>
              <a href="#information-security">
                LIMITACIÓN O REVOCACIÓN PARA EL TRATAMIENTO DE SUS DATOS
              </a>
            </li>
            <li>
              <a href="#data-breach">PERMISOS SENSIBLES</a>
            </li>
            <li>
              <a href="#changes-and-amendments">MEDIDAS DE SEGURIDAD</a>
            </li>
            <li>
              <a href="#acceptance-of-this-policy">
                MODIFICACIONES AL AVISO DE PRIVACIDAD
              </a>
            </li>
            <li>
              <a href="#contacting-us">CONSENTIMIENTO DEL TITULAR</a>
            </li>
            <li>
              <a href="#contacting-us">LEGISLACIÓN APLICABLE Y JURISDICCIÓN</a>
            </li>
            <li>
              <a href="#contacting-us">INFORMACIÓN</a>
            </li>
          </ol>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            1. IDENTIDAD Y ALCANCE.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            El presente aviso de privacidad es aplicable únicamente para la
            aplicación móvil denominada “Casas Pecsa”, en lo sucesivo
            identificada indistintamente como la (“Aplicación Móvil” o
            “Servicio”) y/o cualquiera de sus productos y/o servicios
            relacionados (colectivamente como “ los “Servicios”), propiedad de{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Para efectos del presente Aviso de Privacidad, se considerará
            USUARIO a: (i) el público en general y/o (ii) al cliente de{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, que
            haya celebrado cualquier Contrato de Prestación de Servicios, (el
            “USUARIO”). El USUARIO acepta plena e incondicionalmente todas y
            cada una de las disposiciones del Aviso de Privacidad, publicado de
            manera permanente en la Aplicación Móvil. El presente Aviso de
            Privacidad se remitirá para todo lo no definido aquí, a los Términos
            y Condiciones.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, se reservan el derecho de efectuar en cualquier momento
            modificaciones o actualizaciones al Aviso de Privacidad para la
            atención de novedades legislativas, políticas internas o nuevos
            requerimientos para la prestación u ofrecimiento de nuestros
            servicios o productos. Dichas modificaciones se notificarán a través
            de la Aplicación Móvil.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            El USUARIO reconoce que ingresa y proporciona su información
            personal, (“Información Personal” o “Datos Personales”), al Portal
            y/o a <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>,
            de manera libre y voluntaria.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, son responsables del tratamiento de los datos personales
            que recabe por sí mismo y en respecto al derecho de privacidad y a
            la autodeterminación informativa de las personas en cumplimiento al
            artículo 16 Constitucional y a la disposiciones establecidas en la
            Ley Federal de Protección de datos Personales en Posesión de los
            Particulares (en adelante la “LFPDPPP”) y su Reglamento, atendiendo
            los principios de licitud, consentimiento, información, calidad,
            finalidad, lealtad, proporcionalidad y responsabilidad.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Al utilizar la Aplicación Móvil, usted acepta los términos y
            condiciones del aviso de privacidad y de nuestros procesos de
            recabar datos personales para los fines descritos en el presente. Si
            usted no está de acuerdo con nuestro aviso de privacidad por favor
            no utilice la Aplicación Móvil.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            2. FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, es una
            empresa líder en la industria de la construcción y desarrollo de
            parques industriales en México, así como proporcionar servicios
            integrales para el desarrollo de infraestructuras y administración
            de los parques industriales, que satisfagan las expectativas y
            requerimientos en la industria nacional e internacional a través de
            un proceso de mejora continua, que genere ventajas competitivas a
            los clientes. Asimismo, busca utilizar los datos personales de los
            ciudadanos de la República mexicana, tales como, nombre completo,
            dirección, lugar y fecha de nacimiento, correo electrónico, Clave
            Única de Registro de Población, Registro Federal de Contribuyentes,
            teléfonos fijo y/o celular, datos de facturación tales como (Nombre
            de la persona física o moral, Numero o código de identificación
            fiscal, domicilio fiscal, Código Postal, Población), empresa para la
            cual labora, nacionalidad, lugar de nacimiento, ocupación entre
            otros, para los siguientes fines:
          </Typography>
          <ol type="a">
            <li>Comerciales.</li>
            <li>Reclutamiento.</li>
            <li>Negocios.</li>
            <li>Proveeduría.</li>
            <li>Estadísticas.</li>
            <li>Mercadotecnia.</li>
            <li>Crear y gestionar cuentas de usuario.</li>
            <li>Enviar información administrativa.</li>
            <li>Responder consultas y/o atención a soporte.</li>
            <li>Operar y gestionar la Aplicación Móvil.</li>
            <li>Otros fines relacionados.</li>
          </ol>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            3. TRANSFERENCIAS.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Los Datos Personales del USUARIO, pueden ser tratados dentro y fuera
            de la República Mexicana.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, podrán transferir sus Datos Personales a terceros, en
            términos de lo previsto en el artículo 37 de la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares,
            cuando: (i) la transferencia sea efectuada a sociedades
            controladoras, subsidiarias o afiliadas bajo el control común del
            responsable, o a una sociedad matriz o a cualquier sociedad del
            grupo responsable que opere bajo los mismos procesos y políticas
            internas; (ii) la transferencia esté prevista en una Ley o Tratado
            en los que México sea parte; (iii) la transferencia sea necesaria
            por virtud de un contrato celebrado o por celebrar en interés del
            titular, por el responsable y un tercero; (iv) la transferencia sea
            necesaria o legalmente exigida para salvaguardar un interés público,
            o para la procuración o administración de justicia; (v) la
            transferencia sea precisa para el reconocimiento, ejercicio o
            defensa de un derecho en un proceso judicial; y/o (vi) la
            transferencia sea precisa para el mantenimiento o cumplimiento de
            una relación jurídica entre el responsable y el USUARIO, titular de
            los datos personales.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Si el USUARIO no manifiesta su oposición para que sus Datos
            Personales sean transferidos, se entenderá que ha otorgado su
            consentimiento para ello.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            4. CONTACTO.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            El USUARIO tiene derecho de acceder a sus Datos Personales y a los
            detalles del tratamiento de los mismo, así como a rectificarlos en
            caso de ser inexactos o incompletos; cancelarlos cuando considere
            que no se requieren para alguna de las finalidades señaladas en el
            presente Aviso de Privacidad, estén siendo utilizados para
            finalidades no consentidas, o haya concluido la relación contractual
            o de servicio, o bien, oponerse al tratamiento de estos para fines
            específicos.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, con
            domicilio comercial ubicado en Saltillo, Coahuila de Zaragoza,
            México, a través de su Responsable en el Tratamiento de Datos
            Personales: Manuel Eduardo González Lomelí, pone a su disposición la
            siguiente dirección de correo electrónico{" "}
            <a href="mailto:soporteapp@casaspecsa.com">
              soporteapp@casaspecsa.com
            </a>
            , así como el teléfono +52 844 101 6022, para atender cualquier
            solicitud relacionada a derechos de acceso, rectificación,
            cancelación u oposición de Datos Personales (derechos ARCO), así
            como para atender cualquier solicitud de revocación del
            consentimiento para el tratamiento de Datos Personales, mismas que
            serán atendidas conforme a los plazos establecidos en la LFPDPPP.
            Dicha solicitud deberá incluir: (i) el derecho (ARCO) que desea
            ejercer, (ii) nombre y/o razón social, RFC y teléfono de contacto,
            (iii) documentos que acrediten la personalidad (copia de
            identificación oficial o poder en escritura pública); se dará
            trámite y respuesta a la solicitud, mediante correó electrónico o al
            domicilio señalado, dentro de los 7 (siete) días hábiles posteriores
            a la recepción de la solicitud.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Si el USUARIO considera que su derecho de protección de Datos
            Personales ha sido lesionado por alguna conducta de Proyectos y
            Edificaciones Civiles Pecsa, S.A. de C.V. o sus afiliadas, el
            USUARIO podrá interponer la queja o denuncia correspondiente ante el
            IFAI a través del Portal web www.ifai.org.mx.{" "}
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            5. LIMITE DE RESPONSABILIDAD.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Hasta el máximo permitido por las leyes aplicables,{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, no serán responsables, en ningún caso, por cualquier daño
            directo, indirecto, especial, incidental o consecuencial que en
            cualquier forma, se deriven o se relacionen con: (i) el uso o
            ejecución de la Aplicación Móvil; (ii) el retraso o falta de
            disponibilidad de uso de la Aplicación Móvil; (iii) la proveeduría o
            falta de proveeduría de servicios de cualesquier información,
            gráficos contenidos o publicados en o a través de la Aplicación
            Móvil (iv) de la actualización o falta de actualización de la
            información; (v) que la información haya sido alterada o modificada,
            en todo o en parte, luego de haber sido incluida en la Aplicación
            Móvil; (vi) cualquier otro aspecto o característica de la
            información contenida o publicada en la Aplicación Móvil o a través
            de las ligas que eventualmente se incluyan en el mismo; (vii) la
            proveeduría o falta de proveeduría de los servicios.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, no son, ni serán responsables del uso, contenido,
            veracidad, calidad o precisión de la información contenida en la
            Aplicación Móvil, incluyendo, pero sin limitar, la publicada por{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, y/o la proporcionada por el USUARIO a través de cualquier
            medio.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            El USUARIO libera expresamente a{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, y a
            sus afiliadas, de cualquier responsabilidad derivada de lo anterior
            y se obliga a sacar en paz y salvo a{" "}
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, y a
            sus afiliadas, cubriendo los daños y perjuicios que le sean
            aplicables de las reclamaciones que: (i) pudiera llegar a hacerle
            cualesquier tercero; (ii) surjan de cualquier requerimiento,
            reclamación y/o procedimiento judicial y/o administrativo derivado
            del incumplimiento de obligaciones del USUARIO reglamentadas en la
            legislación vigente y/o aquellas que pudiera adquirir o haber
            adquirido.{" "}
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            6. MODIFICACIONES.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, podrán unilateralmente, en cualquier momento, para
            cumplir con la legislación vigente y/o cuando considere conveniente,
            realizar correcciones, adiciones, mejoras o modificaciones al
            contenido, presentación, información, servicios, áreas, bases de
            datos y demás elementos de la Aplicación Móvil. El USUARIO reconoce
            que no tiene derecho a ninguna reclamación, indemnización y/o pago
            de los daños y perjuicios causados por los cambios efectuados.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            7. LIMITACIÓN O REVOCACIÓN PARA EL TRATAMIENTO DE SUS DATOS.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Le informamos que se recabará el uso de los datos personales
            sensibles que más adelante se enumeran, por lo que se les dará
            tratamiento conforme a los lineamientos aquí establecidos, por lo
            que le solicitamos autorización para el tratamiento de los
            siguientes datos personales sensibles:
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Sin embargo, tratándose de la revocación para el uso de sus datos
            personales, es importante que tenga en cuenta que no en todos los
            casos podremos atender su solicitud o concluir el uso de forma
            inmediata, ya que es posible que por alguna obligación legal
            requiramos seguir tratando sus datos personales. Así mismo, usted
            deberá considerar que, para ciertos fines, la revocación de su
            consentimiento implicará que no le podamos seguir prestando el
            servicio que nos solicitó.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            8. PERMISOS SENSIBLES.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Le informamos que se recabará el uso de los datos personales
            sensibles que más adelante se enumeran, por lo que se les dará
            tratamiento conforme a los lineamientos aquí establecidos, por lo
            que le solicitamos autorización para el tratamiento de los
            siguientes datos personales sensibles:
          </Typography>
          <ol type="a">
            <li>Datos Personales.</li>
            <li>Dirección.</li>
            <li>Cámara.</li>
            <li>Almacenamiento.</li>
          </ol>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            9. MEDIDAS DE SEGURIDAD.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            De igual forma, mediante el presente Aviso de Privacidad se hace de
            su conocimiento que, como parte de nuestra preocupación en cuanto al
            adecuado tratamiento y cuidado de sus datos personales, mantenemos
            diversos mecanismos, procedimientos y medidas de seguridad tanto
            administrativas, como técnicas y físicas tendientes a lograr la
            protección de dicha información contra daño, pérdida, alteración,
            destrucción o uso no autorizado.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            10. MODIFICACIONES AL AVISO DE PRIVACIDAD.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            El presente Aviso de Privacidad estará siempre disponible para su
            consulta, y en caso de existir cambios sustanciales o totales en el
            presente Aviso de Privacidad o en el que en su momento se le
            proporcione, se pondrá a su disposición la versión actualizada del
            mismo en nuestra Aplicación Móvil.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            En caso de cualquier duda respecto al presente Aviso de Privacidad,
            usted podrá ponerse en contacto con el responsable enviando un
            correo a{" "}
            <a href="mailto:soporteapp@casaspecsa.com">
              soporteapp@casaspecsa.com
            </a>
            .
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            11. CONSENTIMIENTO DEL TITULAR.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Usted manifiesta que los Datos Personales, esto es, los datos
            generales y financieros que son materia del presente Aviso de
            Privacidad han sido obtenidos de manera libre, informada, voluntaria
            e inequívoca y que usted consiente que sus datos personales sean
            tratados conforme a lo señalado en el presente Aviso de Privacidad.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            12. LEGISLACIÓN APLICABLE Y JURISDICCIÓN.
          </Typography>

          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            Este instrumento será regido e interpretado de acuerdo con las leyes
            de los Estados Unidos Mexicanos. Cualquier controversia que surja de
            la interpretación o ejecución del Aviso de Privacidad, se someterá a
            la jurisdicción exclusiva de los tribunales competentes en la ciudad
            de Saltillo, Coahuila de Zaragoza, México por lo que el USUARIO
            renuncia a cualquier otra jurisdicción que sea aplicable en virtud
            de sus domicilios presentes o futuros o cualquier otra
            circunstancia.
          </Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          <Typography
            component={"h3"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
            variant="heading3"
            id="collection-of-information"
          >
            13. INFORMACIÓN.
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, podrán recibir comunicaciones relacionadas a la
            prestación de sus servicios y/o al Aviso de Privacidad a la
            dirección de correo electrónico:{" "}
            <a href="mailto:soporteapp@casaspecsa.com">
              soporteapp@casaspecsa.com
            </a>
            .
          </Typography>
          <Typography
            component={"p"}
            sx={{ fontFamily: "Montserrat", color: theme.palette.common.black }}
          >
            <b>Proyectos y Edificaciones Civiles Pecsa, S.A. de C.V.</b>, o sus
            afiliadas, seleccionarán el medio de comunicación que considere
            apropiado, incluyendo el uso de mensajes de datos, para responder a
            cualquier solicitud de información que deba atender conforme a
            derecho.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export { AvisoPrivacidad };
